export default {
  v: '5.9.4',
  fr: 25,
  ip: 0,
  op: 43,
  w: 96,
  h: 96,
  ddd: 0,
  assets: [],
  layers: [
    {
      ddd: 0,
      ind: 2,
      ty: 4,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: -45, ix: 10 },
        p: { a: 0, k: [48, 48.625, 0], ix: 2, l: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 }
      },
      ao: 0,
      hasMask: true,
      masksProperties: [
        {
          inv: false,
          mode: 'a',
          pt: {
            a: 1,
            k: [
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                t: 13.357,
                s: [
                  {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0]
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0]
                    ],
                    v: [
                      [-25.367, -15.88],
                      [-43.399, 0.501],
                      [-28.903, 16.882],
                      [-22.641, 12.629],
                      [-16.411, 8.397]
                    ],
                    c: true
                  }
                ]
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                t: 14.143,
                s: [
                  {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0]
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0]
                    ],
                    v: [
                      [-33.028, -14.348],
                      [-51.059, 2.033],
                      [-36.563, 18.414],
                      [-30.301, 14.161],
                      [-24.071, 9.929]
                    ],
                    c: true
                  }
                ]
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                t: 14.929,
                s: [
                  {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0]
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0]
                    ],
                    v: [
                      [-31.496, -14.938],
                      [-49.527, 1.444],
                      [-35.031, 17.825],
                      [-28.769, 13.571],
                      [-22.539, 9.34]
                    ],
                    c: true
                  }
                ]
              },
              {
                t: 15.7138671875,
                s: [
                  {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0]
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0]
                    ],
                    v: [
                      [-4.39, -29.315],
                      [-48.584, 0.972],
                      [-34.088, 17.354],
                      [0.015, 42.936],
                      [18.237, 27.724]
                    ],
                    c: true
                  }
                ]
              }
            ],
            ix: 1
          },
          o: { a: 0, k: 100, ix: 3 },
          x: { a: 0, k: 0, ix: 4 }
        }
      ],
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  v: [
                    [27.341, -0.177],
                    [0.017, 0],
                    [0.034, 27.417]
                  ],
                  c: false
                },
                ix: 2
              },
              hd: false
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.086273193359, 0.094116210938, 0.098052978516, 1],
                ix: 3
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 7, ix: 5 },
              lc: 2,
              lj: 2,
              bm: 0,
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 90, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 }
            }
          ],
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          hd: false
        },
        {
          ty: 'tm',
          s: { a: 0, k: 100, ix: 1 },
          e: {
            a: 1,
            k: [
              {
                i: { x: [0.361], y: [1] },
                o: { x: [0.63], y: [0] },
                t: 13.357,
                s: [100]
              },
              { t: 33, s: [0] }
            ],
            ix: 2
          },
          o: { a: 0, k: 0, ix: 3 },
          m: 1,
          ix: 2,
          hd: false
        }
      ],
      ip: 0,
      op: 1000,
      st: 0,
      ct: 1,
      bm: 0
    },
    {
      ddd: 0,
      ind: 3,
      ty: 4,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: -45, ix: 10 },
        p: { a: 0, k: [48, 48.625, 0], ix: 2, l: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  v: [
                    [27.341, -0.177],
                    [0.017, 0],
                    [0.034, 27.417]
                  ],
                  c: false
                },
                ix: 2
              },
              hd: false
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.086273193359, 0.094116210938, 0.098052978516, 1],
                ix: 3
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 7, ix: 5 },
              lc: 2,
              lj: 2,
              bm: 0,
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 90, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 }
            }
          ],
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          hd: false
        },
        {
          ty: 'tm',
          s: { a: 0, k: 0, ix: 1 },
          e: {
            a: 1,
            k: [
              {
                i: { x: [0.923], y: [1] },
                o: { x: [0.63], y: [0] },
                t: 0,
                s: [100]
              },
              { t: 19.642578125, s: [0] }
            ],
            ix: 2
          },
          o: { a: 0, k: 0, ix: 3 },
          m: 1,
          ix: 2,
          hd: false
        }
      ],
      ip: 0,
      op: 1000,
      st: 0,
      ct: 1,
      bm: 0
    },
    {
      ddd: 0,
      ind: 4,
      ty: 4,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: -45, ix: 10 },
        p: { a: 0, k: [48, 48.625, 0], ix: 2, l: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  v: [
                    [27.341, -0.177],
                    [0.017, 0],
                    [0.034, 27.417]
                  ],
                  c: false
                },
                ix: 2
              },
              hd: false
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.23137254902, 0.341176470588, 0.827450980392, 1],
                ix: 3
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 7, ix: 5 },
              lc: 2,
              lj: 2,
              bm: 0,
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 90, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 }
            }
          ],
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          hd: false
        }
      ],
      ip: 0,
      op: 1000,
      st: 0,
      ct: 1,
      bm: 0
    }
  ],
  markers: []
};
